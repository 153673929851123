import * as _setimmediate2 from "setimmediate";

var _setimmediate = "default" in _setimmediate2 ? _setimmediate2.default : _setimmediate2;

var exports = {};
_setimmediate;
const crypto = self.crypto || self.msCrypto; // TODO: synchronous version no longer supported in browser

exports = class MessageDigest {
  /**
   * Creates a new MessageDigest.
   *
   * @param algorithm the algorithm to use.
   */
  constructor(algorithm) {
    // check if crypto.subtle is available
    // check is here rather than top-level to only fail if class is used
    if (!(crypto && crypto.subtle)) {
      throw new Error("crypto.subtle not found.");
    }

    if (algorithm === "sha256") {
      this.algorithm = {
        name: "SHA-256"
      };
    } else if (algorithm === "sha1") {
      this.algorithm = {
        name: "SHA-1"
      };
    } else {
      throw new Error(`Unsupported algorithm "${algorithm}".`);
    }

    this._content = "";
  }

  update(msg) {
    this._content += msg;
  }

  async digest() {
    const data = new TextEncoder().encode(this._content);
    const buffer = new Uint8Array(await crypto.subtle.digest(this.algorithm, data)); // return digest in hex

    let hex = "";

    for (let i = 0; i < buffer.length; ++i) {
      hex += buffer[i].toString(16).padStart(2, "0");
    }

    return hex;
  }

};
export default exports;